import { fetchApi } from "@/utils/axios";
import URL from "@/api/urlConfig"
/**
 * @description: 服务咨询管理列表 
 * @param {*} data page:当前页  size:当前条数 projectId:项目ID serverStatus:处理状态 workId:处理人
 * @return {*}
 */
export function serverConsultationList (data) {
  return fetchApi(URL.SERVER_CONSULTATION_LIST, data, 'get','params',true)
}
/**
 * @description: 服务咨询详情
 * @param {*} serverConsultingId 服务咨询ID
 * @return {*}
 */
export function serverConsultationDetail (serverConsultingId) {
  return fetchApi(URL.SERVER_CONSULTATION_DETAIL + '/' + serverConsultingId, null, 'get')
}
/**
 * @description: 服务咨询-跟踪记录添加
 * @param {*} data
 * @return {*}
 */
export function serverConsultationRecordAdd (data) {
  return fetchApi(URL.SERVER_CONSULTATION_RECORD_ADD, data, 'post', 'data')
}
/**
 * @description: 服务咨询受理咨询/结束咨询
 * @param {*} serverConsultingId 服务咨询ID
 * @param {*} status 状态
 * @return {*}
 */
export function serverConsultationStatus (serverConsultingId, status) {
  return fetchApi(URL.SERVER_CONSULTATION_STATUS + '/' + serverConsultingId + '/' + status, null, 'put')
}
/**
 * @description: 服务咨询-跟踪记录
 * @param {*} data serverConsultingId 服务咨询ID page:当前页  size:当前条数
 * @return {*}
 */
export function serverConsultationRecordList (data) {
  return fetchApi(URL.SERVER_CONSULTATION_RECORD, data, 'get')
}
/**
 * @description: 服务咨询-服务跟踪记录详情
 * @param {*} followRecordId 服务跟踪记录ID
 * @return {*}
 */
export function serverConsultationRecordDetail (followRecordId) {
  return fetchApi(URL.SERVER_CONSULTATION_RECORD_DETAIL + '/' + followRecordId, null, 'get')
}
/**
 * @description: 服务咨询-跟踪记录编辑
 * @param {*} data
 * @return {*}
 */
export function serverConsultationRecordEdit (data) {
  return fetchApi(URL.SERVER_CONSULTATION_RECORD_EDIT, data, 'put', 'data')
}
/**
 * @description: 服务咨询-跟踪记录-删除
 * @param {*} followRecordId  服务跟踪记录ID
 * @return {*}
 */
export function serverConsultationRecordDelete (followRecordId) {
  return fetchApi(URL.SERVER_CONSULTATION_RECORD_DELETE + '/' + followRecordId, null, 'delete')
}
/**
 * @description: 服务类型列表
 * @param {*} data page:当前页 size:当前条数 projectid :项目ID  serverTypeStatus:服务类型
 * @return {*}
 */
export function serverTypeList (data) {
  return fetchApi(URL.SERVER_TYPE_LIST, data, 'get','params',true)
}
/**
 * @description: 服务类型详情
 * @param {*} serverTypeId 服务类型ID
 * @return {*}
 */
export function serverTypeDetail (serverTypeId) {
  return fetchApi(URL.SERVER_TYPE_DETAIL + '/' + serverTypeId, {}, 'get',true)
}
/**
 * @description: 服务类型编辑
 * @param {*} data
 * @return {*}
 */
export function serverTypeEdit (data) {
  return fetchApi(URL.SERVER_TYPE_EDIT, data, 'put', 'data')

}
/**
 * @description: 服务类型添加
 * @param {*} data
 * @return {*}
 */
export function serverTypeAdd (data) {
  return fetchApi(URL.SERVER_TYPE_ADD, data, 'post', 'data')
}
/**
 * @description: 服务类型删除
 * @param {*} serverTypeId 服务类型ID
 * @return {*}
 */
export function serverTypeDelete (serverTypeId) {
  return fetchApi(URL.SERVER_TYPE_DELETE + '/' + serverTypeId, null, 'delete')
}
/**
 * @description: 服务类型启用/禁用
 * @param {*} serverTypeId 服务类型ID
 * @param {*} serverTypeStatus 服务类型状态
 * @return {*}
 */
export function serverTypeEnable (serverTypeId, serverTypeStatus) {
  return fetchApi(URL.SERVER_TYPE_ENABLE + '/' + serverTypeId + '/' + serverTypeStatus, null, 'put')
}
/**
 * @description: 获取所属项目
 * @return {*}
 */
export function getProjectList () {
  return fetchApi(URL.GET_ALL_PROJECT_LIST, null, 'get')
}
/**
 * @description: 获取服务管理处理人
 * @return {*}
 */
export function getServerUserList () {
  return fetchApi(URL.GET_SERVER_USER_LIST, null, 'get')
}
/**
 * @description: 
 * @return {*} 服务咨询获取所属项目
 */
export function getServerProject () {
  return fetchApi(URL.GET_SERVER_PROJECT, null, 'get')
}
/**
 * @description: 供应商管理列表
 * @param {*} data page:当前页面 size:当前条数  supplierName 供应商名称 projectId 项目ID
 * @return {*}
 */
export function getSupplierList (data) {
  return fetchApi(URL.SUPPLIER_LIST, data, 'get','params',true)
}
/**
 * @description: 添加供应商
 * @param {*} data
 * @return {*}
 */
export function addSupplier (data) {
  return fetchApi(URL.ADD_SUPPLIER, data, 'post', 'data')
}
/**
 * @description: 编辑供应商
 * @param {*} data
 * @return {*}
 */
export function editSupplier (data) {
  return fetchApi(URL.EDIT_SUPPLIER, data, 'put', 'data')
}
/**
 * @description: 供应商详情
 * @param {*} supplierId 供应商ID
 * @return {*}
 */
export function supplierDetail (supplierId) {
  return fetchApi(URL.SUPPLIER_DETAIL, supplierId, 'get')

}
/**
 * @description: 删除供应商
 * @param {*} supplierId 供应商ID
 * @return {*}
 */
export function deleteSupplier (supplierId) {
  return fetchApi(URL.DELETE_SUPPLIER + '/' + supplierId, null, 'delete')
}
/**
 * @description: 启用/禁用供应商
 * @param {*} data supplerState:启禁用 0:启用 1:禁用 supplierId:供应商ID
 * @return {*}
 */
export function enableSupplier (data) {
  return fetchApi(URL.ENABLE_SUPPLIER, data, 'put', 'data')
}
/**
 * @description: 根据项目集合查询供应商集合
 * @param {*} data projectIdList 供应商关联项目id集合
 * @return {*}
 */
export function selectSupplierList (data) {
  return fetchApi(URL.SELECT_SUPPLIER_LIST, data, 'post', 'data',true)
}
// 获取供应商
export function getSupplier () {
  return fetchApi(URL.GET_SUPPLIER, {}, 'get','params',true)
}
// 顺义获取项目接口
export function getShunyiAllProject(data) {
  return fetchApi(URL.GET_SHUNYI_ALL_PROJECTLIST, data, 'post', 'data',true);
}
// 判断是否为租户
export const isTenant = (data) => {
  return fetchApi(URL.IS_TENANT , data, 'get', 'data')
}

/********** 服务模块管理 **********/
/**
 * 获取服务模块列表
 * @param {String} params.serverBindingName 服务板块名称
 * @param {Number} params.status 状态（0：禁用 1：启用）
 * @param {Number} params.pageNo 页码(必传)
 * @param {Number} params.pageSize 数量(必传)
 * @returns 
 */
export const getServicePlateList = (params) => fetchApi(URL.GET_SERVICE_PLATE_LIST, params);

/**
 * 添加服务模块
 * @param {String} data.serverBindingName 服务板块名称(必传)
 * @param {Number} data.status 状态（0：禁用 1：启用）(必传)
 * @returns 
 */
export const addServicePlate = (data) => fetchApi(URL.ADD_SERVICE_PLATE, data, 'post', 'data');

/**
 * 获取服务模块详情
 * @param {String} params.serverBindingId 服务板块绑定ID(必传)
 * @returns 
 */
export const getServicePlateDetail = (params) => fetchApi(URL.GET_SERVICE_PLATE_DETAIL, params);

/**
 * 编辑服务模块
 * @param {String} data.serverBindingId 服务板块绑定ID(必传)
 * @param {String} data.serverBindingName 服务板块名称(必传)
 * @param {Number} data.status 状态（0：禁用 1：启用）(必传)
 * @returns 
 */
export const editServicePlate = (data) => fetchApi(URL.EDIT_SERVICE_PLATE, data, 'put', 'data');

/**
 * 设置服务模块状态
 * @param {String} data.serverBindingId 服务板块绑定ID(必传)
 * @param {Number} data.status 状态（0：禁用 1：启用）(必传)
 * @returns 
 */
export const setServicePlateStatus = (data) => fetchApi(URL.SET_SERVICE_PLATE_STATUS, data, 'put', 'data');

/**
 * 删除服务模块
 * @param {String} data.serverBindingId 服务板块绑定ID(必传)
 * @returns 
 */
export const deleteServicePlate = (data) => fetchApi(URL.DELETE_SERVICE_PLATE, data, 'delete', 'data');

/**
 * 查询可绑定服务或供应商列表
 * @param {String} params.serverBindingId 服务模块绑定ID(必填)
 * @param {Number} params.type 查询类型（ 1：查询服务类型 2：查询机构）(必填)
 * @returns 
 */
export const findAllowBindingServiceTypeOrSupplierList = (params) => fetchApi(URL.FIND_ALLOW_BINDING_SERVICE_TYPE_OR_SUPPLIER_LIST, params);

/**
 * 添加服务模块
 * @param {String} data.serverBindingId 服务模块绑定ID(必填)
 * @param {Array} data.bindingTypeList 服务类型绑定集合(必填与bindingSupplierList二选一)
 * @param {Array} data.bindingSupplierList 机构绑定集合(必填与bindingTypeList二选一)
 * @returns 
 */
export const bindingServiceTypeOrSupplier = (data) => fetchApi(URL.BINDING_SERVICE_TYPE_OR_SUPPLIER, data, 'post', 'data');

/**
 * 查询已绑定服务类型或供应商列表
 * @param {String} params.serverBindingId 服务模块绑定ID(必填)
 * @param {Number} params.type 查询类型（ 1：查询服务类型 2：查询机构）(必填)
 * @param {String} params.title 名称
 * @param {Number} params.pageNo 页码(必填)
 * @param {Number} params.pageSize 数量(必填)
 * @returns 
 */
export const getAlreadyBindingServiceTypeOrSupplier = (params) => fetchApi(URL.GET_ALREADY_BINDING_SERVICE_TYPE_OR_SUPPLIER, params);

/**
 * 更新已绑定服务类型排序
 * @param {String} data.id 绑定表的ID(必传)
 * @param {Number} data.sort 序号(必传)
 * @returns 
 */
export const updateAlredayBindingServiceTypeSort = (data) => fetchApi(URL.UPDATE_ALREDAY_BINDING_SERVICE_TYPE_SORT, data, 'put', 'data');

/**
 * 删除绑定信息
 * @param {String} data.id 绑定表的ID(必传)
 * @param {Number} data.type 类型（ 1：服务类型 2：服务机构）(必传)
 * @returns 
 */
export const deleteBindindInfo = (data) => fetchApi(URL.DELETE_BINDIND_INFO, data, 'delete', 'data');