<!--
 * @Author: wxy
 * @Date: 2022-07-05 14:11:41
 * @LastEditTime: 2022-08-13 14:47:18
 * @Description: 服务咨询跟踪记录
-->
<template>
  <div class="serverRecord">
    <div class="header">
      <ds-header title="服务跟踪记录" style="width: 100%"></ds-header>
      <a-button type="primary" @click="() => this.$router.go(-1)">返回</a-button>
    </div>
    <div style="margin: 10px 0" v-if="enableOperation">
      <a-button type="primary" @click="addRecord"> <a-icon type="plus" /> 新增跟踪记录 </a-button>
    </div>
    <a-table
      :rowKey="(record, index) => index"
      :columns="table.columns"
      :data-source="table.tableData"
      :pagination="table.pagination.total ? table.pagination : false"
      @change="pageChange"
      bordered
      :scroll="{ x: 1500 }"
    >
      <div slot="serverTime" slot-scope="text, record">
        {{ text.serverTimeStart }}&nbsp;&nbsp;至&nbsp;&nbsp;{{ text.serverTimeEnd }}
      </div>
      <div class="serverContent" slot="serverContent" slot-scope="text">
        <a-tooltip>
          <template slot="title">
            <span>{{ text.serverContent }}</span>
          </template>
          <div>
            {{ text.serverContent }}
          </div>
        </a-tooltip>
      </div>
      <span slot="action" slot-scope="text, record" class="action" v-if="enableOperation">
        <a-button type="link" @click="edit(record)" class="btn"> 编辑</a-button>
        <a-button type="link" @click="deleteRecord(record)" style="color: #f5222d" class="btn"> 删除</a-button>
      </span>
    </a-table>
    <a-modal v-model="isShowDialog" :title="dialogTitle" width="600px" @ok="submit" v-if="isShowDialog">
      <a-form-model ref="ruleForm" :model="form" :label-col="{ span: 5 }" :wrapper-col="{ span: 17 }" :rules="rules">
        <a-form-model-item label="服务时间" prop="serverTime">
          <a-range-picker
            v-model="serverTime"
            style="width: 390px"
            :show-time="{ format: 'HH:mm:ss' }"
            format="YYYY-MM-DD HH:mm:ss"
            :placeholder="['开始时间', '结束时间']"
            @change="onChangeTime"
          />
        </a-form-model-item>
        <a-form-model-item label="服务内容描述" prop="serverContent">
          <a-textarea
            placeholder="请输入服务内容描述，不超过200个字符"
            :max-length="200"
            v-model.trim="form.serverContent"
            :autoSize="{ minRows: 5, maxRows: 5 }"
          />
        </a-form-model-item>
      </a-form-model>
    </a-modal>
  </div>
</template>

<script>
import pageRules from "@/utils/pagingConfig/pageUtils.js";
import * as api from "@/api/entrepreneurClubManagement/serverManagement";
import moment from "moment";
export default {
  data() {
    let checkServerTime = (rule, value, callback) => {
      if (this.serverTime.length !== 0) {
        return callback();
      }
      // 不合法
      callback(new Error("请选择服务时间"));
    };
    return {
      table: {
        columns: [
          {
            title: "服务时间",
            key: "serverTime",
            scopedSlots: { customRender: "serverTime" },
            width: 350,
          },
          {
            title: "服务内容描述",
            key: "serverContent",
            width: 300,
            scopedSlots: { customRender: "serverContent" },
          },
          {
            title: "创建时间",
            dataIndex: "gmtCreate",
            key: "gmtCreate",
            width: 180,
          },
          {
            title: "创建人",
            dataIndex: "createIdShow",
            key: "createIdShow",
            width: 150,
          },
          {
            title: "最后编辑时间",
            dataIndex: "gmtModified",
            key: "gmtModified",
            width: 180,
            customRender(text) {
              return text ? text : "-";
            },
          },
          {
            title: "最后编辑人",
            dataIndex: "modifiedIdShow",
            key: "modifiedIdShow",
            width: 150,
            customRender(text) {
              return text ? text : "-";
            },
          },
          {
            title: "操作",
            key: "action",
            width: 150,
            className: "action-ql-list",
            scopedSlots: { customRender: "action" },
          },
        ],
        tableData: [],
        pagination: {
          pageSizeOptions: ["10", "20", "30", "50", "100"],
          showSizeChanger: true,
          current: 1,
          pageSize: 10,
          total: 0,
          showQuickJumper: true,
          showTotal: (total) => {
            return `共 ${total} 条`;
          },
        },
      },
      // 弹框标题
      dialogTitle: "",
      // 是否展示弹窗
      isShowDialog: false,
      form: {
        // 服务时间开始时间
        serverTimeStart: "",
        // 服务时间结束时间
        serverTimeEnd: "",
        // 服务内容
        serverContent: "",
      },
      rules: {
        serverContent: [{ required: true, message: "请输入服务内容", trigger: "blur" }],
        serverTime: [{ required: true, validator: checkServerTime, trigger: "change" }],
      },
      // 操作状态
      actionStatus: "",
      // 服务时间
      serverTime: [],
      // 服务是否结束
      enableOperation: false,
    };
  },
  mounted() {
    this.getList();
  },
  methods: {
    // 获取列表
    async getList() {
      const data = {
        page: this.table.pagination.current,
        size: this.table.pagination.pageSize,
        consultingId: this.$route.query.id,
      };
      try {
        const res = await api.serverConsultationRecordList(data);
        if (res.code === "200") {
          this.table.tableData = res.data.list;
          this.table.pagination.total = res.data.total;
          this.enableOperation = res.data.enableOperation;
          if (!res.data.enableOperation) {
            this.table.columns.splice(this.table.columns.length - 1, 1);
          }
        }
      } catch (error) {}
    },

    // 新增跟踪记录
    addRecord() {
      this.form = {
        // 服务时间开始时间
        serverTimeStart: "",
        // 服务时间结束时间
        serverTimeEnd: "",
        // 服务内容
        serverContent: "",
      };
      this.serverTime.length = 0;
      this.dialogTitle = "新增跟踪记录";
      this.isShowDialog = true;
      this.actionStatus = "add";
    },
    // 获取详情
    async getDetail(fellowRecordId) {
      try {
        const res = await api.serverConsultationRecordDetail(fellowRecordId);
        if (res.code === "200") {
          this.form = res.data;
          this.serverTime.push(this.form.serverTimeStart);
          this.serverTime.push(this.form.serverTimeEnd);
        } else {
          this.$message.error(res.msg);
        }
      } catch (error) {}
    },
    // 编辑
    edit(record) {
      this.dialogTitle = "编辑跟踪记录";
      this.isShowDialog = true;
      this.actionStatus = "edit";
      this.getDetail(record.id);
    },
    // 切换分页
    pageChange(option) {
      this.table.pagination.current = option.current;
      this.table.pagination.pageSize = option.pageSize;
      this.getList();
    },
    // 删除
    async deleteRecord(record) {
      const _this = this;
      _this.$confirm({
        title: "确认删除",
        content: "删除后该数据无法恢复，确认删除吗？",
        async onOk() {
          try {
            const res = await api.serverConsultationRecordDelete(record.id);
            if (res.code === "200") {
              _this.table.pagination.current = pageRules.pageRules(
                _this.table.tableData.length,
                1,
                _this.table.pagination.current
              );
              _this.$message.success("删除成功");
              _this.getList();
            } else {
              _this.$message.error(res.msg);
            }
          } catch (error) {}
        },
        onCancel() {},
      });
    },
    // 时间处理
    onChangeTime(date, dateString) {
      this.serverTime = dateString;
      this.form.serverTimeStart = dateString[0];
      this.form.serverTimeEnd = dateString[1];
    },
    // 确定
    submit() {
      this.$refs.ruleForm.validate(async (valid) => {
        if (valid) {
          if (this.actionStatus === "edit") {
            try {
              const data = {
                ...this.form,
                consultingId: this.$route.query.id,
              };
              data.serverTimeStart = moment(data.serverTimeStart).valueOf();
              data.serverTimeEnd = moment(data.serverTimeEnd).valueOf();
              const res = await api.serverConsultationRecordEdit(data);
              if (res.code === "200") {
                this.$message.success("操作成功");
                this.isShowDialog = false;
                this.getList();
              } else {
                this.$message.error(res.msg);
              }
            } catch (error) {}
          } else {
            try {
              const data = {
                ...this.form,
                consultingId: this.$route.query.id,
              };
              data.serverTimeStart = moment(data.serverTimeStart).valueOf();
              data.serverTimeEnd = moment(data.serverTimeEnd).valueOf();
              const res = await api.serverConsultationRecordAdd(data);
              if (res.code === "200") {
                this.$message.success("操作成功");
                this.isShowDialog = false;
                this.getList();
              } else {
                this.$message.error(res.msg);
              }
            } catch (error) {}
          }
        } else {
        }
      });
    },
  },
};
</script>

<style lang='scss' scoped>
.header {
  display: flex;
  justify-content: space-between;
}
.tips-color {
  color: #d9d9d9;
}
.btn{
  padding: 4px;
}
.serverContent{
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3; // 控制多行的行数
  -webkit-box-orient: vertical;
}
</style>